// import React from 'react';
// import ReactDOM from 'react-dom';
// import './styles.css';
// import App from './App';

// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   document.getElementById('root')
// );


// import React from 'react';
// import ReactDOM from 'react-dom/client';
// import './styles.css';
// import App from './App';

// const container = document.getElementById('root');
// const root = ReactDOM.createRoot(container);

// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );

import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles.css';
import App from './App';

const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);

root.render(
  <App />
);
