import React from 'react';
// import './Character.css';

const Character = () => {
  return (
    <img src="/img/character_01_removebg.png" alt="Character" className="character" />
  );
}

export default Character;
