import React, { useState } from 'react';

const Page3 = () => {
  const [selectedWord, setSelectedWord] = useState('');
  const [translation, setTranslation] = useState('');

  let apiUrl = process.env.REACT_APP_API_URL;

  const handleWordClick = (word) => {
    // 단어 클릭 시 번역 가져오기
    fetch(`/api/translate?word=${word}`)
      .then(response => response.json())
      .then(data => setTranslation(data.translation));
    setSelectedWord(word);
  };

  return (
    <div className="page">
      <h2>3단계</h2>
      <p>
        <span onClick={() => handleWordClick('substitute')}>substitute</span>, 
        <span onClick={() => handleWordClick('sneeze')}>sneeze</span>, ...
      </p>
      <p>{selectedWord}: {translation}</p>
    </div>
  );
}

export default Page3;
