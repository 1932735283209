import React from 'react';

let apiUrl = process.env.REACT_APP_API_URL;

const Page2 = () => {

  const handleSTT = () => {
    // STT 기능 구현 코드
  };

  return (
    <div className="page">
      <h2>2단계</h2>
      <button onClick={handleSTT}>마이크</button>
    </div>
  );
}

export default Page2;
