import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Recorder from 'recorder-js';
import '../styles.css'; // CSS 파일을 임포트

let apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:5000'; // 환경 변수로 API URL 설정

const Page1 = () => {
  const [initialText, setInitialText] = useState('');
  const [sttText, setSttText] = useState('');
  const [speachText, setSpeachText] = useState('');
  const [hasFetched, setHasFetched] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [recorder, setRecorder] = useState(null);
  const [audioUrl, setAudioUrl] = useState('');
  const [contextArray, setContextArray] = useState([]); // context를 배열로 저장하기 위한 상태 추가
  const [highlightIndexes, setHighlightIndexes] = useState([]); // 하이라이트 인덱스 저장 상태 추가
  const [isLoading, setIsLoading] = useState(false); // 로딩 상태 추가

  useEffect(() => {
    if (!hasFetched) {
      // 초기 예문을 받아오는 API 요청
      axios.post(`${apiUrl}/show_text`, {}, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        console.log('Response:', response.data);
        setInitialText(response.data.context); // API 응답에 따라 데이터 구조 수정
        
        const context = response.data.context;
        console.log('context:', context);

        const contextArray = context.split(' '); // 띄어쓰기로 구분하여 배열에 담기
        setContextArray(contextArray); // 배열을 상태에 저장
        console.log('contextArray:', contextArray);

        setHasFetched(true); // 데이터가 성공적으로 가져왔을 때 true로 설정
      })
      .catch(error => {
        console.error('There was an error fetching the initial text!', error);
      });

      // WAV 파일 URL을 받아오는 API 요청
      axios.post(`${apiUrl}/get_audio`, {}, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        console.log('Audio Response:', response.data);
        setAudioUrl(`${apiUrl}${response.data.audioUrl}`); // API 응답에 따라 데이터 구조 수정
      })
      .catch(error => {
        console.error('There was an error fetching the audio URL!', error);
      });
    }
  }, [hasFetched]);

  useEffect(() => {
    if (speachText && Array.isArray(speachText)) {
      // speachText에서 "Correct"가 아닌 데이터의 인덱스 추출
      const highlightIndexes = speachText.map((wordObj, index) => {
        const key = Object.keys(wordObj)[0];
        return wordObj[key] !== "Correct" ? index : -1;
      }).filter(index => index !== -1);

      setHighlightIndexes(highlightIndexes); // 하이라이트 인덱스를 상태에 저장
      console.log('highlightIndexes:', highlightIndexes);
    }
  }, [speachText]);

  const toggleRecording = () => {
    if (isRecording) {
      stopRecording();
    } else {
      startRecording();
    }
  };

  const startRecording = () => {
    navigator.mediaDevices.getUserMedia({ audio: true })
      .then(stream => {
        const audioContext = new (window.AudioContext || window.webkitAudioContext)();
        const recorder = new Recorder(audioContext);
        recorder.init(stream);
        setRecorder(recorder);
        recorder.start().then(() => setIsRecording(true));
      })
      .catch(err => console.error('Error accessing audio devices.', err));
  };

  const stopRecording = () => {
    setIsLoading(true); // 로딩 상태를 true로 설정
    recorder.stop().then(({ blob }) => {
      setIsRecording(false);
      uploadRecording(blob); // 녹음이 중지되면 파일을 자동으로 업로드
    }).catch(err => {
      console.error('Error stopping recording.', err);
      setIsLoading(false); // 에러 발생 시 로딩 상태를 false로 설정
    });
  };

  const uploadRecording = (blob) => {
    const audioFile = new File([blob], 'recording.wav', { type: 'audio/wav' });

    // 파일을 서버로 업로드
    const formData = new FormData();
    formData.append('file', audioFile);

    axios.post(`${apiUrl}/upload_audio`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    .then(response => {
      console.log('File uploaded successfully:', response.data);
      // 업로드 후 stt 변환 데이터를 가져옴
      setSttText(response.data.stt_result); // API 응답에 따라 데이터 구조 수정
      console.log('stt_result:', response.data.stt_result);

      const parsedSpeachText = JSON.parse(response.data.speach_text);
      setSpeachText(parsedSpeachText); // API 응답에 따라 데이터 구조 수정
      console.log('speachText:', parsedSpeachText);
      setIsLoading(false); // 로딩 상태를 false로 설정
    })
    .catch(error => {
      console.error('There was an error uploading the audio file!', error);
      setIsLoading(false); // 에러 발생 시 로딩 상태를 false로 설정
    });
  };

  return (
    <div className="page">
      <h2>The Substitute</h2>
      
      {isLoading && (
        <div className="spinner-overlay">
          <img src="/img/spinner_tutor_02.gif" alt="Loading..." className="spinner" />
        </div>
      )}

      {/* 제시된 문장 */}
      <div className='each-sentence'>
        <p>Tutor : {initialText}</p>
        {audioUrl && <audio controls src={audioUrl}></audio>}
      </div>
      
      {/* 학생 읽는 파트 */}
      <div className='each-sentence'>
        {/* <span className="text-block">Right: {initialText}</span> */}
        <p className="text-block">
          Right: {contextArray.map((word, index) => (
            <span key={index} className={highlightIndexes.includes(index) ? 'highlight' : ''}>{word} </span>
          ))}
        </p>
        
        <div>
          <button className='button-no-border' onClick={toggleRecording}>
            <img
              src={isRecording ? '/img/mic-on.png' : '/img/mic-off.png'}
              alt="Mic"
              className={isRecording ? 'blink' : ''}
              style={{ width: '30px', height: 'auto' }}
            />
          </button>
          <span>Click the button and repeat the sentence.</span>
        </div>
      </div>

      <div className='each-sentence'>
        <span className="text-block">You said: {sttText}</span>
      </div>

      {/* <div className='each-sentence'>
        <p className="text-block">
          Right: {contextArray.map((word, index) => (
            <span key={index} className={highlightIndexes.includes(index) ? 'highlight' : ''}>{word} </span>
          ))}
        </p>
      </div> */}
    </div>
  );
}

export default Page1;
