import React from 'react';
import { Link } from 'react-router-dom';

const Navigation = () => {
  return (
    <div className="navigation">
      <Link to="/">1단계</Link>
      <Link to="/page2">2단계</Link>
      <Link to="/page3">3단계</Link>
      <Link to="/page4">4단계</Link>
    </div>
  );
}

export default Navigation;
