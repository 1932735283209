import React, { useState } from 'react';

let apiUrl = process.env.REACT_APP_API_URL;

const Page4 = () => {
  const [question, setQuestion] = useState('');
  const [answer, setAnswer] = useState('');

  const handleQuestionChange = (e) => {
    setQuestion(e.target.value);
  };

  const handleAsk = () => {
    // 질문에 대한 답변 가져오기
    fetch(`/api/ask?question=${question}`)
      .then(response => response.json())
      .then(data => setAnswer(data.answer));
  };

  return (
    <div className="page">
      <h2>4단계</h2>
      <textarea value={question} onChange={handleQuestionChange}></textarea>
      <button onClick={handleAsk}>질문하기</button>
      <p>{answer}</p>
    </div>
  );
}

export default Page4;
